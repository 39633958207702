import axios from 'axios'
import configData from '@/config'

export  function  isNewVersion (){
    console.log('当前版本',configData.version)
    let oldVersion = configData.version;
    let localSeagullCloudVersion = localStorage.getItem('contractVersion');
    if (localSeagullCloudVersion && localSeagullCloudVersion != oldVersion) {
        localStorage.setItem('contractVersion', oldVersion);              
        window.location.reload();
        return;
    } else {
        localStorage.setItem('contractVersion', oldVersion);
    }
    return

}

